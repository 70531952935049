<template src="./accountadjustment.html"></template>
<style scoped src="./accountadjustment.css"></style>
<script>
import accountService from '@/services/AccountService.vue'
import modal from '@/components/modal/notification/modal'
import loading from 'vue-loading-overlay'
import transactionService from '@/services/TransactionService.vue'
import { required, email } from 'vuelidate/lib/validators'
import moment from 'moment'
import pagination from '@/components/vtable/vtpagination.vue'
import applicationConstants from '@/constants/AppConstants.vue'

export default {
    components: {
        modal, loading, pagination
    },
    data () {
        return {
            customerEmail: null,
            currentAccountBalance: null,
            accBalCurrency:  null,
            isCredited: false,
            adjustmentAmount: null,
            remarks: null,
            transactionSummary: [],
            columns :['DateTime','ReferenceNumber','Amount','AdjustmentType','TransactionDetails'],
            options : {
                headings : {
                    AdjustmentType: 'AdjustmentType',
                    TransactionDetails: 'Remarks'
                }
            },
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            loaderVisible: false,
            enableLazyLoading: applicationConstants.enableLazyLoading
        }
    },
    methods: {
        async retriveBalanceAndSummary () {
            this.$v.customerEmail.$touch()
            if(this.$v.customerEmail.$invalid) {
                return
            }
            this.loaderVisible = true
            await accountService.methods.GetAccountBalance(this.customerEmail)
            .then(async res => {
                this.currentAccountBalance = res.csAccountBalanceResponse?.Balance?._text ?? null
                this.accBalCurrency = res.csAccountBalanceResponse?.Currency?._text ?? null
                await this.callTransactionSummary();
                this.loaderVisible = false
            })
            .catch(err => {
                this.loaderVisible = false
                this.customerEmail = null
                this.currentAccountBalance = null
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        async callTransactionSummary() {
            await transactionService.methods.GetTransactionSummary({
                TransactionType: '7',
                CustomerEmail: this.customerEmail
            })
            .then(async res => {
                this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                this.transactionSummary = this.transactionSummary ? (Array.isArray(this.transactionSummary) ? (this.transactionSummary) : [this.transactionSummary]) : []
                if (this.enableLazyLoading) {
                    this.$refs.paginationRef.selectedPage = 1
                } else {
                    this.$refs.vueClientTable.setPage(1)
                }
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        async addAccountAdjustment() {
            this.$v.$touch()
            if(this.$v.$invalid) {
                return 
            }
            this.loaderVisible = true
            await accountService.methods.addAccountAdjustment({
                Amount: this.adjustmentAmount,
                Email: this.customerEmail,
                IsCredit: this.isCredited ? 'true' : 'false',
                Remarks: this.remarks
            })
            .then(async ()=> {
                await this.callTransactionSummary()
                this.loaderVisible = false
                this.showAlert(1, "Account Adjustment Added Successfully")
                this.clearAll()
            })  
            .catch(err => {
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        allowOnlyNumber($event) {          
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46) && keyCode !== 190) {
                $event.target.value =  $event.target.value.substring(0,  $event.target.value.length - 1)
            }           
            if ($event.target.value !== null && $event.target.value.indexOf('.') > -1  && ($event.target.value.split('.')[1].length > 6)) {
                $event.target.value =  $event.target.value.substring(0,  $event.target.value.length - 1)
            }
            
        },
        mapConstants_ManipulateData(transactionSummary) {
            transactionSummary.forEach(transaction => {
                transaction['AdjustmentType'] = (transaction['IsCreditAdjustment']).toUpperCase() === 'FALSE' ? 'Debit' : 'Credit'
                transaction['DateTime'] = transaction['DateTime'] ? this.UTCtoLocal(transaction['DateTime']) : null
            })
            return transactionSummary
        },
        clearAll() {
            this.customerEmail = null
            this.adjustmentAmount = null
            this.isCredited = false
            this.remarks = null
            this.currentAccountBalance = null
            this.accBalCurrency = null
            this.$v.$reset()
        },
        removeUnderScoreText(data) {
            if(data) {
                data = Array.isArray(data) ? data : [data]  
                data.forEach(transaction=> {
                    Object.keys(transaction).map(key => transaction[key] = transaction[key]?._text ? transaction[key]._text : null)
                })
                return data
            }else{
                return []
            }
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        UTCtoLocal (utcTime) {
            return utcTime? moment.utc(utcTime, 'MM/DD/YYYY hh:mm:ss').local().format("MM/DD/YYYY hh:mm:ss A"): null
        },
    },
    computed: {
        accountClosingBalance () {
            if(this.adjustmentAmount && this.currentAccountBalance) {
                return this.isCredited ? parseFloat(this.adjustmentAmount) + parseFloat(this.currentAccountBalance) : parseFloat(this.currentAccountBalance) - parseFloat(this.adjustmentAmount)
            } else {
                return null
            }
        },
        dataTableTransactionSummary() {
            let transactionSummary = []
            if (this.transactionSummary && this.transactionSummary.length > 0) {
                let selectedPage = this.$refs.paginationRef?.selectedPage
                if(this.enableLazyLoading) {
                    transactionSummary = this.removeUnderScoreText(JSON.parse(JSON.stringify(this.transactionSummary)).slice((selectedPage - 1) * 10, selectedPage * 10))
                } else {
                    transactionSummary = this.removeUnderScoreText(JSON.parse(JSON.stringify(this.transactionSummary)))
                }
                transactionSummary = this.mapConstants_ManipulateData(JSON.parse(JSON.stringify(transactionSummary)))
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return transactionSummary || []
        }
    },
    mounted () {
    },
    validations: {
        customerEmail: {required, email},
        adjustmentAmount: {required},
        remarks: {required}
    }
}
</script>
